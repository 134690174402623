import "@hotwired/turbo-rails";
import "@rails/actioncable";
import { application } from "controllers/application";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

import MintEM from "controllers/mint_em_controller";

const context = require.context("controllers", true, /_controller\.js$/);
application.load(definitionsFromContext(context));

import * as Sentry from "@sentry/browser";
import Rails from "@rails/ujs";

Sentry.init({
  dsn: "https://e7cae43300234c36b84c6bfdcf20c2cb@o1036852.ingest.sentry.io/6089350",
});

Rails.start();
